import React, { useState, useEffect } from "react";
import { AiFillCamera } from "react-icons/ai";
import Overlay from "../img/snapshot.png";
import axios from "axios";
import { saveAs } from "file-saver";
import { set } from "firebase/database";

const Snapshot = () => {
  const test = [
    { id: "c074b69d8ed6e7db1aa9a9c0501f19b09d3245ca26225492196d9ee116c5d024i0" },
    { id: "ea7e72ec577401ceff09be2f798f04e402a70dcb9d8ad7ff210421cd69f40ac9i0" },
    { id: "82d65806d87a536c21b9f9466de86d5f4022050d31a14a27cb1d552493d3cbbdi0" },
    { id: "cbfc51f4e1c9af56509524522fdd2959631d7e274f6692b412a5a9743ff0b560i0" },
    { id: "b6db5774852619ae0e0a12e7db35db17d16d507c11797bfeacff7948bd8b05c7i0" },
    { id: "d56ad63370e866e99d198790a130cb6fd727e4fb43a991a27e4a5f3bcb88534di0" },
    { id: "bf62a3c2064e4303325f004d5a5bb7210253b8e07f54bdfc990396daa0550669i0" },
    { id: "796a1311979ebd9fbb19f68d2a40e5d17ee55e204e04c020b9dd2b2511e00ef4i0" },
    { id: "ff3ce9ddd5dd36fd8938199d0b0c8601b491d9122db680162ffb8a9ea7662bfei0" },
    { id: "db54734bd6a0a6b37884214701463ed930bd099ad9f7984831ae8509a01fd4cei0" },
    { id: "b00976c4bba21d2a32b4473834c47f08dbc5ade4716b4faa46d78ff6df9a6d0fi0" },
    { id: "71ca184a1614bc3f9dbd7cd5e1f239e1ee87e34973bdc8ab6474cc68fd15cda8i0" },
    { id: "95c639854e10310cf1fca301d1d3c074c3e2d9638ca9292045ed8a036e0f98c9i0" },
    { id: "80ca12052c430148574b45475c888d157c283b669f21603df93eb897adbdaca6i0" },
    { id: "5e0f1513fe2813b6e710ff4fc284b4213e7cd6aff25fdc32d148cf87ce65609ai0" },
    { id: "478ba055d18772d7d3e7d47684e344f8fbe01c7ed5e3f2fe5285b8cc6d6f70f1i0" },
    { id: "8455be8b3ed5c97ae886be5ab2b92e63c82a9eca7878fe4fd665d8d97f6fe208i0" },
    { id: "40887579538450c63a7368c3173f246c2a22cd0c770814f3a660f300b66f30d0i0" },
    { id: "1841cee35e107d83770b3febc97960f5da36a08a75a9dbe5bbd24ecfbe2d208fi0" },
    { id: "7d5c3859441a96cc438d6f418704ef75c71a25b9d0604a1a2ac455531df2bc20i0" },
    { id: "66789bacf8a1389afe989380e76dc69fb81d067625819b0e646ed3f6bd3dba36i0" },
    { id: "80418c8d9cb9de02a219f1d376ad738c52bb74050f16b7e5ea4b138b395a891fi0" },
    { id: "7395b0203c664dd957d80a5d04d370ca332d40b7bcf798729d98b38666b329f7i0" },
    { id: "1f77933ffeca1c8fabb62cc0db35fbd75652ac20c208700a1391e9e53a481b10i0" },
    { id: "331ca0b819019f46a8ff8d15f6991b05cc5efd9787697fcd821ca6abbddec80ci0" },
    { id: "f50dcce29568c4c054e3f03edc97e1955ac10fc06d1d67c6c71ff7cd0942ec94i0" },
    { id: "0020408a4cd28fe656f79df3b3f99f93c88a6e76ee075f0a37a9b62373cef4d1i0" },
    { id: "755af03499fd566b6a1fc737cd25debe03470832d8cd0618d5e46b52e0f7e118i0" },
    { id: "7d5e858292303601d4c1c621128c99934beb56cbc9027a89e70e8e71252e0a8ci0" },
    { id: "02fbceb65ddde29feda7df6fd483cd18c8679afb9ab52ed9bc6b981af3de0af2i0" },
    { id: "e368be442797c90c7c57d66ba9c72a92ec755166ad6cbc37294ff89a6aa6f864i0" },
    { id: "3f5e365240d902127d8db909edfbb0c21d61d46aa82849d53a2129cc125a5d05i0" },
    { id: "b5a4971805c3b91618ef3233347c99302b03a840d3ea54ce9abbc77badad85bdi0" },
    { id: "f10354db6574f8fb836a187ed91abcd93048adf980d79dcd433d995a10de4e20i0" },
    { id: "b074453d031d5c38331e92ae898ba50c3a7e7f3edf331ecda2d4041062dd274di0" },
    { id: "1f29438a361a07b0e26edfd3e2434e7d4a059fea6b98000b1ffe11865da41093i0" },
    { id: "24a0559df2837a467f85f09064e190df50f69a6e88b59d53540b657e27884f64i0" },
    { id: "a0fe8e78eea023e0c87bae48d9dcc30e2e449189ddc671e99c0db609cf182175i0" },
    { id: "edacbb61eb6974498d158b8466f2057e57c2864ff9ba295f105ac7febe6c7051i0" },
    { id: "94c0bb00067cf3ffd7117b175f00dcecd3dec76421fde8902bb1dee1585f21f0i0" },
    { id: "3bfd1d5d1d36bf07fae0da31df95f6be92cce27177b609ada7898c804f8b6e47i0" },
    { id: "1543f4d13022f9bc1233b6843543a27b1e25d8ae811c30734fd4509dc1869aadi0" },
    { id: "b2d49d68f0662e8a2161e5b6d0257912bf58a9c5f74fe6f816643a11d734b1a7i0" },
    { id: "9d0680809748226c5a02e6725e8e721fb20bb6e92c4e20bc5ce287282930054ei0" },
    { id: "9a11f8fbf9d9a200c9680fd7cf645bd5fc5acae92699f56cb73c417a5ee9adb9i0" },
    { id: "5ce761a0edc65bd82eb8b9c9f5cde0e70554c5f119c489c29bfa504c2d0bd92fi0" },
    { id: "b1161557eb913b52a8ab7abad812354454bdfc948a01e12d7177d8177361f487i0" },
    { id: "1efd7e56dd8e850b24a74f5f679d2920e143bc406b4f2baa8ca476b143817766i0" },
    { id: "df879be3f9ce9a626e326ab47ad7c68506a439c43c55f27361931d75e5876369i0" },
    { id: "042641565c42daadbd0765a51cbe913dd768427f0f9e2359c8cd48085153587ci0" },
    { id: "4c3eb32f722868ec9429f1b21bc10d0e87b260e5811d3ce554fa23c72d77f1cdi0" },
    { id: "6efe7b4fae084aaf5202609fc4aa4feed3d4aa1ea5a903a3af6f603ed720a50ei0" },
    { id: "0f2173eb7e6ac9f35a466f17397f78b812339236e56eb48c1fa3a0aa915a83fei0" },
    { id: "1dc58aab11a0bab807654b739fc481a985a2eba8ef61723f2597f712b6f3c7a1i0" },
    { id: "ca591250b4bdd56e0bf9d9e871c749e9e108534fd554ef626c1d2b7317373aaci0" },
    { id: "168301fdc3101b1ac26d11bbb4c301ebb1c1677b3a8c9efc24eee7a78aec92fci0" },
    { id: "cd178b6cee752f08d1fc00bb0f98d3d45b84209a1beb528e58724b3c3815d3d5i0" },
    { id: "fb20313360e965bebaf1203297a4cabdd0e01c7573a8c2ef0c4cd65b6cf783efi0" },
    { id: "8db404dff116fa49e4d2f4bc3821a695fc8fecdccdd22a49c21c40cbe157ffc0i0" },
    { id: "4d51f46cb8de91e10d8c4dfad08adaa199b261beba1a56da0022963b702a1aedi0" },
    { id: "be34ec71aa08823d65702f547a812914f5402f897a094fc2d7f9d53d6a146ab9i0" },
    { id: "aec84aeac2e0db6d1509dd15505f32344d986f8bd602eef52d11bff79791a7f7i0" },
    { id: "82665f3d327072737c735c52c1c50103b666aa98bb65abe526041bd618ec82d1i0" },
    { id: "d1ac2b7c60644003d189e660e9e65daee62c66e05507a8ee142adbdcdd7d4ca3i0" },
    { id: "4dbfd54b3b25d85e4a7336463d1b836b580271e84f0f39f7eba366ef3efa05b9i0" },
    { id: "eae4bfcc847f02b1f59ca146734d0cd3d9dba82576c0885b6200f507ef6d8acfi0" },
    { id: "e8ded1db56bb8941676c8774336a44835684a706d74decf02d15a624dd0a4f5ei0" },
    { id: "1ffff5395625e9eb74a3778a25b38572fec7b8db27d54c57df8dffe2c3d8a684i0" },
    { id: "b63c4dc38029b377ed7196a7b8fa44a4790f5b00cacd8c230ae8808db758a62ei0" },
    { id: "dad84bc1b3e0f85726a1b464b4c4333316ff9cb2a4515e06461824d48ce0377ci0" },
    { id: "f94dc454a2ec245c5b73951021425e8b70c82eec75405a4f0a2068abb02e2e0ei0" },
    { id: "a7a945d2dc7689f99bd8b745ca96c92d85035723c22a6ed009c64f6ef18b6f83i0" },
    { id: "368fb734182b2bb2e736992a570ef883307899d237545dc4525c46f3a9906b24i0" },
    { id: "1ed6a73bd403d70a95933f7617bfeadb5a2617c985e059f4ba8a3425bf8d0c5bi0" },
    { id: "c805d9003d8e070e8a05a61774bac4cdcb4ee221c160af6f60f246f8e1d3fd0bi0" },
    { id: "c5eade30c20963b1e0d42241ebfad638483b168d2977aeebd2fbcac7aab2b479i0" },
    { id: "1013554f38befa7cfb995198e4ce6c21790d392672501ccbcfc62948e6982b1ci0" },
    { id: "a488b7bf847a71a531671644088227d4157f0c03e39248f5ef678d86057e3c78i0" },
    { id: "da2f27b0437d27b793c5ad0b74cacf5018e5331c8bd2f697125d0f08af0830e2i0" },
    { id: "c6df4cc39b1b0ee7745f12a38cc70233f2a9d1263a9c0d5c4107b26e431a3a16i0" },
    { id: "5b47c9f5be91b64b14b9a9d2cdd761375a7c586df2666208d1b9da8912f03b2ci0" },
    { id: "bdac867c3f57925b5e1e57d93652e54d504ce5846ed0283dc15072d16c2dea4ai0" },
    { id: "487fdc279587fbcd85ebc6a4c7d96107aadae3d9ba5594f28e75fef11b1becedi0" },
    { id: "ca3cb6413a2cf69cc880e609bb11f54a6afe4de60123524cee63e9efd86a755fi0" },
    { id: "9be0a443f2a4e213e4ec0f3847d9c86f4562ec1fa44057e25b9c3c8ea47dbfcci0" },
    { id: "bbd7f125168fd9310daebae4886571cfceee71358f05a62544633669d2f8d33di0" },
    { id: "d4e1a546585bfe6a4430f5e7b16541c31f08471d6ae0f3af8475b89440bc9f1di0" },
    { id: "78a8b13b09b1553e6faf72478c287fc770dd69f5d9059376f299482ffcedc91di0" },
    { id: "4a57f89b36d3c9b47e81493576a714f95706d5d58d8a6875807bf846f64d9d43i0" },
    { id: "9a2c3790dde8742b59c2b4bd9ccf6605f88c82346200d1f64364a32f566c7152i0" },
    { id: "518d179328f3c5c1383d6f5a4bd1f090b66b9f255d285ddbc6f45605fce7514ai0" },
    { id: "e512c1618fac67eca2a948d36979ad0324cd6593402d1c5f1aa31b1a829e6417i0" },
    { id: "85f6d80430f007c206ba6d31d6233d4806c57959ea24fc9cb51cab070fe067ddi0" },
    { id: "28a6f6335ac6e72c9beee9e219f09df780c38dac8b46db6a9bb2836e1bd27e9ai0" },
    { id: "b9e140c3b75dd69bc63fbe46edf329b519fd0e82ebc628481b98555bd553ae20i0" },
    { id: "0c99c460e775a3c04ffefcee793e21c3dfe1f38b74d072f6d3a6ca8b06220fcci0" },
    { id: "9717c42352b22087b33f670c2e3b8da66129226f373405bf3bac82d203960b5ei0" },
    { id: "54b0b2cbe32e02117c052313af60988a9c92cff2cd9d6a2068a98722359947d5i0" },
    { id: "7585732c55a24b6ff6d2a277265f645bc7deec9a3128ce97d4c8ef5ce1717da4i0" },
    { id: "789b71de51f7cef90fe87474a4aaf38184f78499e716db8eb465aeed38b2d855i0" },
    { id: "9e04e39d1499ef744f3f1c283779a221bd54cf04f6f4b8f7656f31c48b9c80ffi0" },
    { id: "5c18fb41ff17ed920da2e9f515fa7887223f297353c7c110d63ce1ec98c799f6i0" },
    { id: "2115150da4412d345f8a031d638b0a51533fc0b3f5befd7e26d9e6d7d2625973i0" },
    { id: "52b6a5dcbb82d17b4f16057106f55b0c060168ee860e1b6072fc01cad4e2a30ei0" },
    { id: "5c963edb5cddfc5d741b3f13611008dd4cae4e48e5ec835d86b8aa1804d78ba2i0" },
    { id: "e78641fc801768224b1860d537388238afa933e18df3203a6da43c4894a5b16fi0" },
    { id: "df8bbde62b2297439fe8eacc053a63791d37e4ae89f9dac492a695aec96f9663i0" },
    { id: "e323e8aff8bc191158adfbd8169e84efce1d2ee6ed831c19a17c09bf9b7134cbi0" },
    { id: "43980028e703e6f21503a6857203a842b07f3834dc12dffd8b0f2db9c7248cf1i0" },
    { id: "dd40616968be195098e6b0f56eea7e3a56def82340544555171f982ed7ce9cf2i0" },
    { id: "17f5f24c41b6d829d2d1681ea4da3fec800641ae7a86f52eabe83bf95a90d1c4i0" },
    { id: "7d5366759f17944b5ddd08f594d679013b78457004985cc1f887f98c35202965i0" },
    { id: "9a5628b720ef18fa2bbde37624617c25adda0fa5c0b7a9efa18bbb33d9b1e013i0" },
    { id: "a7dd1ffb3c69d635dcd497dacc2aaeeda89df97740ef9c6eaaebd1bfb0a6263bi0" },
    { id: "2d8fc1615dae0cdf218cefb515c60bcf18f80139abb04282de65f0a2a9adfa47i0" },
    { id: "fd14d10dbea90d9b0cb70502849221517d56fa7938b5a6bffb4b56b49874b1e3i0" },
    { id: "db1625b326aa697919d6810ab345b58453aa88bd92a2d04b138bcf61a2e72514i0" },
    { id: "343969d74144ab6d77e5940e62c3bfc5162de8461c0b16cb3677f0aa664105fbi0" },
    { id: "a2908b2dc45fffaf4e37558dc291fca6dd6549fb2ccd664368c0ce7c6e46f690i0" },
    { id: "77dcb21ea0d57187388991c5b4916328acd600eb2bdda0f5ff3efef346e02ffai0" },
    { id: "60061689be2d7ceebff0987258db2941fa35e3f57542df398c5ac3b9ce9a5e59i0" },
    { id: "21e380821e058fadf5ae0199a18c8ae5e4cef9489a9f5102d4e3b2672a2396d9i0" },
    { id: "0622bdc43d9d31d18b3284f116468225366946241626941926081be41d6b0f42i0" },
    { id: "6994b0b615f1acb36d3edc565b1e45e419023e065f05709178f2f9a4d745050ei0" },
    { id: "de5fbf0e1b9aa2da857f125e6d3982f8b69616160ba1c0630564642415e8e401i0" },
    { id: "03a18a352bfa093ce20df812c11daf457982df6dac7e5fcdcd2228996a0e13c5i0" },
    { id: "dc0d96ae8cd192024fa2ea04fdf9fae791bed549c8a77779427f52cc04935a39i0" },
    { id: "cb4918b5997c15674901354ef96a74bb0b8b8698f67517477997df8bef24a5eei0" },
    { id: "4dc6afcdc9443221db800424bf6df2ff3831a9ca329080c5a2ac1a6f191f2e12i0" },
    { id: "1d87783e8905813d3adcb744969145485db98936d45f0e97da0dcdf97a017b74i0" },
    { id: "2b278fb08c482cf6e18ed6213b5e8700872c17bf954b2ef88b2508d279551f38i0" },
    { id: "489a66b8e0109ce40fe3872e63413617e9415f9f9ec334b61552c409bf1dc1e7i0" },
    { id: "6ced4b4b7ae6d966f6a83a926c5a84a0c241d75bb188205ab3d362fb6747dd1ai0" },
    { id: "96d09a2a6e987ebdb9a7df6ba85a85ab744a067399e116502ce6139031de8d88i0" },
    { id: "0a6ce61bddc463bf7877573d082fd0790b2252e2b88e928226107524ef49e14bi0" },
    { id: "eced73f7ba2b8d87ba5865ba2298b0f32290fa832fca241e991063e5ae91bdcfi0" },
    { id: "6fd5c97571a85d57f3d87db7de7237d04580886a9088b492da1fe23e795e2ab7i0" },
    { id: "c5146a9a83bdbf36afd3c55d6466debdc7c8389b420563265cfdfbc8f1260d92i0" },
    { id: "7cabe6e679ca7073af6119517deba4e815ee8adc9b3e5b3f4465cca94de684adi0" },
    { id: "566d74d183e752af5f7dbd807a4f80f5eebaff16661806dd3d72d1e910b2c01bi0" },
    { id: "4b9b15bebaa43f2a6f6ab9c0f03ab443b80e4c6bc0f3ba3b23af2c01f6a37a07i0" },
    { id: "a228a91c882206e1501132e4f444995b7dd7c28294abb3fb0cc53f63ad16ce6ei0" },
    { id: "391092e96904d9c7ede7558dbdc1f9333259bc97c1d0eb6b197a0a4d4e516f9ci0" },
    { id: "5f5a3899080d7dcb47e38517f84e0e0b158f2d88886ec177bd681046cdaf0f0ei0" },
    { id: "dc8c33932461731087022ee4eefd1ef78023bc36a7f6ce79d882406e3f314cd6i0" },
    { id: "afa8c7d8ec2c9f4d470b0efe6b52d8ad25e79971d8d9496c660f02a51de32fc6i0" },
    { id: "7a408293619e0c0f22fbd5cb1dc4ac1452c79399d9f06a1bbfb30dcee67d6410i0" },
    { id: "63cbad2cfa9ccfff2026dff016de6b25de2020ff3c67f0ed755cc771b72aec7ci0" },
    { id: "44de95756ba4c58927f1662081a17e5d0a3d5e406297ff232921d4ef76825a45i0" },
    { id: "3f36f6b10c7f54be2080688b6d3f2a24eda481a99d4ae575836c6c2f53979e94i0" },
    { id: "bf46dd3fb6e3d5f19969e20a87c166215f9144cc6b037a7898045f040155dd61i0" },
    { id: "3878d87433d482b7f6bbd799da14b4350842ba8eb29325216d54982c935ad5e2i0" },
    { id: "6370e1cd79e645825caa63a15bb286b2cef0d013906c96e23479f4b19f31eb5ai0" },
    { id: "00b523abb9e5fb4cfe8eea15323d9c343f752b621756082ff5db206eb23cd205i0" },
    { id: "e3668ab872a9c6ade10da131131b6c9d289286080e57976b6f42458fbc9aa1f0i0" },
    { id: "cba62e6385cf07b5bbe39c60afb7e1a7e9235ce86845e90a21159db9dbd6a191i0" },
    { id: "0d26284a143cb43e30bdcc54993155de4020cf92b977a67d937fe5b6f0661dc3i0" },
    { id: "b74c777b57f9cd51a1d5ed3b1d21fccd0f3c8c6b673acc21f5dec13fb96d6ed7i0" },
    { id: "5f8ade1ac4daa7ec6d5ab1904a054dd9d53b6415919359c8b50f89aab0ef9f25i0" },
    { id: "4136efc0b8ff5d639d98b945c83e08ce25342f4bbecd4a00288304882f11ab04i0" },
    { id: "ab70816b0329516b0a20c3c4a999a734d0a086d3ec56e23fa98d548bed0d64fai0" },
    { id: "d548854c42fe4b486dc2915df24ce882c20289b6ebd2486337e44fd05be4fa30i0" },
    { id: "e77465cbb92d3924a49e69d371cf4044a6d2a159cb9d3f723ba67583e7c07827i0" },
    { id: "f6af49a71895909f4461004a760c49f01f232b4ca8c951293d22845001aa564ei0" },
    { id: "b0301e6bab21a597f224c4c20cec166a5b7f99c6a8ec168f1e301052fdcad1f4i0" },
    { id: "2245949c8e7b2be4c29cf39e37c41f651b371838dbb61ea4f0a5823ec6483897i0" },
    { id: "3428e07382791f33a30165ed59254b1e27566b1893bbdd3c67a8f556f44ab13bi0" },
    { id: "1908b14b6441634830bf0512f75914107034f725ed32b2be8932942463933c4bi0" },
    { id: "e426d6402c5c65590479b4b94ee5810a6a02383e2fde492d3b2782b5b38a3edbi0" },
    { id: "7e3ab9b25568bc5dd65312061c33b06cce1709419ab70928da5957f94691b474i0" },
    { id: "61c9d9a21655e79a525a45679d5cad47a1637ab1fe49ae1fb64ba74c7bed3c01i0" },
    { id: "390946ed4d8fbba8ca6f1040d34f67690d20869c809245d320cc7676cfe00164i0" },
    { id: "bb9a347fdafe4abdcd7f69cbff31da7691db778444c2d0ee2c928f7252f65a04i0" },
    { id: "063b3c347ae80d72670966400e75fadc6b5c8a41695c26dfeb2081b6539ea887i0" },
    { id: "e54c8cd8302ec5666850339f26399590e634a01996e4652f6bf00e610352bf2fi0" },
    { id: "83fd5d2d0585f5e3acf395a652045979abe5d443c7137f720073068fa4605e9ci0" },
    { id: "46d43f87789f1d8122468c6a9a95102e93ef1fc192eaa6fc6e564014fdff3ca6i0" },
    { id: "3ecf9d2194bdff55d1bd9c7846c1896d019fe29ef9ec950b42618292f8e5eff8i0" },
    { id: "8ccceacc57046c8dcd44ebafa7f0e6acf5e91c022283630980763137b0ecec19i0" },
    { id: "fea08b6e87f0314105b2fc60e71e9d10708aff0c4a20567a3f9b71681e1b0445i0" },
    { id: "639f1e25699b0525375c8f84eb7b62141b323ccdbb98f1f1663b341c02f8db55i0" },
    { id: "0d1a430692b4a47c2d06c42290dd977582d065acdea0829b76a3707022e9ab75i0" },
    { id: "ea93d0766efa97377bf91827cf132e50268550fdab531031f5eff3f30ee003c0i0" },
    { id: "1830562229d7b434504bcd9a5d997f9836a2e62f3664d65c3a3cb29e51d2ff58i0" },
    { id: "bfc09e6d2e1e6d2407d785d4467367e89abe9d122fa238ce5aa6de4555fa888fi0" },
    { id: "44614bb82ce62a3daf519c58f661a61ea3439c462ad2768336661ca5201aec19i0" },
    { id: "632023ae8b04a6c844d2b56f8201f8a5e31eb77f808aedf64caac7142a4fcedei0" },
    { id: "7cac36a41eec994db67e67ed04695ea42f8071bd7aba25ff45fb210f73cf11bbi0" },
    { id: "ab99f573798dd5455c32b3d4cd332bdf91b04b2da6dfb7bec672ff78de6e17eci0" },
    { id: "7fbded4ef27ff076b2c0f476a5f63be98db10502e09be0fbad02f4bb01771390i0" },
    { id: "c2b9292a3fa9768237cd7bc4232ac71d80e39f1664622634a7c51aa97fbceaaai0" },
    { id: "2dde9d58fbd645d2f88b18c5ba3509f2ec10cbea9976feeedae99fb0353c8eabi0" },
    { id: "9fc83c81dad745008b93c83bac586c617326b25e24f8480ac0f5a03c36ba3098i0" },
    { id: "9eddef1fe173b23a518773edc04484f3f97b78bb36d5260b75d9922dd5bdc5adi0" },
    { id: "138813dc507fa27ec9c53459b4b81d29d00f8307d532b7cf7b8b0984a91396d1i0" },
    { id: "4aae2b19c518d380f2c0cd230c81bc3d04ab03a14a6f1c8f6fc7fec145658658i0" },
    { id: "699185d731ed35b1ab373b118fdc29fcb322332e62ff68fa18b76058c9f4af0di0" },
    { id: "b589426e186e9b142e2ee86c46c554f999e17648a01ebb0401731a2bf64aa46fi0" },
    { id: "d9ca3b792ed170f1265824416510a99b933cc6ace4f4c9309a5b86090ba6d4fai0" },
    { id: "3fdf38fcc8e074718450790b46f6d67876e24b3d6f766d09be8847bd3a6d248fi0" },
    { id: "1b0a13993eaff33b603ddc6f5b0c4c81fa4e207a759b9def3c51e944c2d5dd2ci0" },
    { id: "d50bdba8ea177b01b04379644d5407c30fd3bedfc1122224a39de2dd6793a862i0" },
    { id: "baf33555faf022a9a2b29ff12defbce8a37550f646854f8a1717ee5b6102e1cci0" },
    { id: "23c241f244c76e79c7da7b36a39b39eb026a076fdff3b2ce35737c48d2b440e0i0" },
    { id: "1d8c50012d8f8a6aebb0aff8298da28c4704c9aca9d1544fac78dc556f6e6d6ei0" },
    { id: "28553217d28e4feac2c2fedf34b35dd52047b186f590741156add2782fdcc3b2i0" },
    { id: "6abdbbaf52031fac92232b346342342c486f64534078f4aa21c75c2b30d829abi0" },
    { id: "844e0e27a9118baa568142e2ddffb4ec8cccb98e34c54ae2bdbaa80a9478da0ei0" },
    { id: "c2e293c073a0aa3fe517befa343f5aa614f51beb25744b9163582b7fb20fceffi0" },
    { id: "a7b1637dc6acf5902d3d0977def575fe9abf14e6cecf83eb0c54fd7ef78e6946i0" },
    { id: "f6c5f2c9d37a3cb318495863d452613af8053393533469c5f3577f59eea55736i0" },
    { id: "7d2bad1b38c301fea2ae58c3278b1bd20d94697dea1c9ac1dc08178eff6b33bdi0" },
    { id: "516d934a8c470c3975ba6a8134a15a208cb8840b0d775a2f18c006a20d094494i0" },
    { id: "25f4b8ecefa6e4b9c5fb1b97c57f40c21db78c05d373f59c4c10999aaf0264c2i0" },
    { id: "e4d2ca4411fcc239d482d8f9a3413a1849b34d2885f54fa190c6cbad55add8d5i0" },
    { id: "369c6f0af4c4ddbe49bbb73ec609738a750478ae4d4257abf8974b133ae297cdi0" },
    { id: "1bc94d4605772c97267d40030d77308ac9b794ac94a7d840dbf4ea456669df9ci0" },
    { id: "88dcbd6373b17d71f06559f175c83032565c357b2c6c65fe6c298275add47601i0" },
    { id: "ec38e0b558fc30680a8bab3b0e013b75322979f31543da7907e9c45da8967b8ai0" },
    { id: "2aaaf5f87763a05e0adda57b4378423a68ea2b1e5417f0cf3f305fbe6249f1a8i0" },
    { id: "0c008dc450fdbb728850b7540e7b174082f77a3033fefacf50b6efb218859ec8i0" },
    { id: "8ef2118fcc6bbb932e3cbb834262068527b043a0184a38f358717d25e3e0832bi0" },
    { id: "1cf586550d27852cb3586d997a41337ece8f69b143cf4585e5a8421be584ebbai0" },
    { id: "d328058f3e19a876c62396180a787ef4407d829a2971d4e04822cc0bcaccd152i0" },
    { id: "157646a703fd79e0efd85b0346453aa19af3ceb60555ba22e5c9f4ea4a7fd0c9i0" },
    { id: "a0dbeb5ba0089f54757d3cb2f9418cde609b2c6c00a42b467ff4ec799305003fi0" },
    { id: "75b9a2c7af6858343d420d4a45c598fec4c594afde23ec6fbdafb8f9177db1c9i0" },
    { id: "2fb09a25f72cc508b18daba08bea9677d8606ea1321c29c8b3ef849686df9e62i0" },
    { id: "ed8a8a3f369e28dc4b2dbfd2769115f013127d975add364d35e25c7a12140134i0" },
    { id: "6a1e2001db706f177a3c9c85da5090bada3d4d18b9d73f21794134e508f99065i0" },
    { id: "ef883935dc43f4ba048163ca0b2ee71f24bc939550d3977fa86b507117516d77i0" },
    { id: "1558d1b49b255642790b1e269f6e6e2e3565ed520033c1acaee4217c06d992b1i0" },
    { id: "1c3f23a3c7eeb61ff5efab3260d6c26eb42305ab4f9e814a6f4f028e90cf15dei0" },
    { id: "309d72627c2c2983a2c56dd87ca5a8c396b00752293a126660d70df9036897f7i0" },
    { id: "e96783d00d6c92c4c38d1cf4453f0ff0defa1472e6f999d79818d6a959752f02i0" },
    { id: "8c07c2afe3060e48079f8a94ba5559431e7c9ede23d8e195af585c033d8acdf2i0" },
    { id: "cba1e1fb47d0553083272ded451598cb1f2900454011cce566e9b812571760e1i0" },
    { id: "c2d27208932729f3c3329dbd16f162ceda63e137265d18cfe3a283c0a52dbe5fi0" },
    { id: "12c6124368c56e7d74dd27243c48f6003a59a77fe215cdbce8396cfe64227dc0i0" },
    { id: "845d0c0e6423bc38d3066c59109953ca2e52ec24343da8025bb46b5bc2bee9d1i0" },
    { id: "0edb097fab850f8253e488383e0a6723d1c809a826cd3cb49bbb020949fdb065i0" },
    { id: "a86d17c008ae954c19ec703131f03f6c3fc7262fb0d4ca189a483c4d8829dc2ai0" },
    { id: "39ce769b88a734e6988084038f5085e47808748d3a691874dc3946729b6854c3i0" },
    { id: "b1b2949d23caa36485543741e153e8efcf0ecee25f4c5fb8f32ee168c9328d81i0" },
    { id: "d6cf6706d7e26f1f031bf08d355458a84b8afff0b384804d5656c4023f59c810i0" },
    { id: "b5eb6cda60cd7998de95e44b3dc26b839756c637311430441de18585ad0cd981i0" },
    { id: "fd318e2310ebec9f843e9ef10d5feccdd919239e3e40d171499a5cf0dec33018i0" },
    { id: "3e0a9366713bdb5e1449ef3baab58becd8f71a5cd44d67b1e39fbbac2202684ei0" },
    { id: "67f5867fda3a8c3ff827d26bb9bdcefba795637b7fa0434cf44d3d5d7c63e0e0i0" },
    { id: "7c6f7e2853db7593c1d20bf3e36e0246841e7228ccb7c9122f603817a8ce2097i0" },
    { id: "99259e379d776b5f6a76841ec36443f11eccfb315e38dde9c611380b7a460dc5i0" },
    { id: "a4c5af9278ead9b0bdcb47ae274d0eb5f3d3940731ac7fab498e2532f232ab5ci0" },
    { id: "2a891d0234c3202b55fe4981be1020b0aea376e1143aaa43c391a5c83a255a83i0" },
    { id: "d53b852325469b0b10b2c7c18f40dc31e0ecd46f034efe5e152d038f494f873ei0" },
    { id: "e6371928052e0c80b739bd52af21c010c483d71cbf810fe8d54ac61a49ded633i0" },
    { id: "3abd03191b02a2aa9802c61953bc598d3f2929feb566b9ef53de33648d654b0bi0" },
    { id: "1ae0f15e5bac605ebff0b9bf306d13de44daf8445e3a940f3b0165d9e0e02b1ei0" },
    { id: "3258db1ef41d7acc6931546e88f1662734904d2b885c71dcac4634f56dc12d1fi0" },
    { id: "e46773f24da4b0b3e3c83e17fdc5d1c4e481cdad19d357f1ecdecbd72d439b49i0" },
    { id: "541f5f14a32f93a64d45a644fbd115ba9b14a775ced6589ef5ce780726283b9ei0" },
    { id: "43cc966c7a0b3ecf4a0323f5056223beb59b4bb7b2b0de6ce6f5c52e3d36b106i0" },
    { id: "f314128fc0464317d60e4f9c7ee52af0cb513731881c29f39d136b1965d47147i0" },
    { id: "eff799487452203797b0bcb3c7bf25724dd800a2724d6274957b655c8eca618bi0" },
    { id: "73ec8ebe6fb19184ac51a312aa197951dd2dc5b647d5bee42875af35de4d6c10i0" },
    { id: "9b244482f873839a8f234f12aa6d55d81a06d5510b340853009e57e7b15d76b3i0" },
    { id: "2b53af33bdcbb7965efdc169b7a5fbd10c68a017bf2182f7603ad7deba27936bi0" },
    { id: "b762593cdfb0867f849ad83cd3ef2d80cff39c4062c324c015a0bec640f86d61i0" },
    { id: "30e4431c58b98f613693d30d56ae4560a89604d97a59e0503d64ce43867a1827i0" },
    { id: "61129b7a1e2f63a221189e6291dd36ece8d65af7fa3434c8838f5ce526304a65i0" },
    { id: "b583cf670b3fbfa4e5fc451412cd0dbbbb250ae868a56138d1abe5157cf0c3e5i0" },
    { id: "58d2a96cd85882c9ee1490b59d4aa5e331e1a21d3d3b64e6207f9874509442eci0" },
    { id: "90028a40f04e674e178138d708a207516b6a4ee531a99cfd95aee6c785a29f1bi0" },
    { id: "7a760d96f1ecc08a282d24616e201dbc850a4c2bedb6c8cbf80c4ead2fe1b2c8i0" },
    { id: "2b8ac6cf667cbb4d65ddb71ebe53601fd359115fdc4f5e22205f0b3bc00128b7i0" },
    { id: "dc2d53bec74637526ce3919721e100f55f6fcea2835bc7ea90e1682249a39e97i0" },
    { id: "31df0bca42f1ce1d99e5c12f760277727dc45ee71e4e4eac8c457b22012627ffi0" },
    { id: "77d47f9a3dfb5f75694387b2860dfb503f5d6015b4b00b10c1a09f44ed7b8c64i0" },
    { id: "d2c2ef8bb6bbb410c358b86d290e94a4339f51ee69198d3590b5936c1c9a71f5i0" },
    { id: "9dfec106fba07d07198028e5c300c019cdbfe207e329120c3640e3800a7e5c07i0" },
    { id: "0a2da67dc1aaa0ac61d89b3bb81139027c7e630c98d3e70d5f5afc24ec5693fai0" },
    { id: "39f989abcd16a5420195adc5ba01fa61dc013e64a50eff18e6f1af4e19cbfeabi0" },
    { id: "2a1f5043e2a029a484f04da099a18f374ecf7b39b2ea071b13ddda60194b1ca3i0" },
    { id: "0fca047099b4908994682a0eca110bd41f85301b784ed7fd377271ff1a1e2a70i0" },
    { id: "4b6508171ce5210a3b409585e857f9594abb394e874bd043a1f5697c8a84979ei0" },
    { id: "ea6fef2debd20ab165877ee783866c565074f3140954b405250cf1ec1ca377c6i0" },
    { id: "016baabfce58a1650703dfd5a07581b298efc81505cf7d668bb25b0418b10c22i0" },
    { id: "878118179760ac4a99fb89009eae7dfa67be6193f172f08e590fcc6f6e83a3bei0" },
    { id: "9526fd3c9658564587808678ed7c5476a3cf4d2fee1a7f0a381d606e34262445i0" },
    { id: "25152e3ee2e06fabfb9deeb92dafc33c41be911a10865b3f5b2ff2f93a1c4fbfi0" },
    { id: "bd3fc6199ec6209f00df4fcbc1689f312a8a1a349dbfc55b29f1ddda063adf02i0" },
    { id: "427f803958cc9c45dc4b7726d97fc0d63a7ac5af354e92bc930d4969825c9beei0" },
    { id: "7fd1430b578b051104fc6b2ecb429aee50e6fa4b21603c97133c31f4b0f11bfei0" },
    { id: "47538bc4c07ada6752b2473caa99d7de9537fa708b1bd9ab70b76e94623ef20ci0" },
    { id: "e8e004ee9add2f66474e431b9ec83a013dbc4caf5139f19b427906f3d733bcbfi0" },
    { id: "9be9777cee9ab613c5c299a1de12a6b4cb1ba86b52a7dbb4a9657d762f15d443i0" },
    { id: "1b98e0e14f8a7d67a98aeb6f1337fc572d2f2b4548a24a2b795c64a0b4daf30ai0" },
    { id: "0233ad482624d7f7defb94c346f0b9cd0bb472c45a2e0b2ba7415dd1616cf927i0" },
    { id: "fdfa403ad93cb32bd0631aa4686a1989aebb615c8069535d18e18977ee453daai0" },
    { id: "d26005b5d20333c118fd0cd8efcbf4f683c0f72b4a4b4a4eca4797f28a880570i0" },
    { id: "f935ddc685a326a0f49c32d34c6000445e1327a96fe3104981457c08b627a3f8i0" },
    { id: "b95f22447eb2909b9f4f8f1dbc5ddcf62a22a0fc30c9383b4fca3f9a8914d53di0" },
    { id: "afa7b7f5265b957469b985b33ad48289343f011814c6f7f610508a5d6b429d53i0" },
    { id: "dd6b2c1bf5b581ac3d6c6a04e318a5e202aedf91fca20deeb61d48fd438d624ci0" },
    { id: "aa747f6a893817c669fd54a3c7379ebe073f6cad3923f1b02d9f0aee497c259fi0" },
    { id: "b360eabb4e2b5fe16a4cf46e034eedd4243ff25d999b90b5737db0894f315ba2i0" },
    { id: "520e3f110ddadb45880ba170414793572bc91be0896392f990fc940746c9e6e7i0" },
    { id: "95855af0b7ce71a68eb460ccb4e7d06d2c4372f9932b3f17f79a956cf11095c7i0" },
    { id: "b11ae80b9f60452049ecbc819740544725638f9d252684b3fddc05279202f1c3i0" },
    { id: "df7330aa652d2af9274dfc1d30f5ce6d3eaa9d68258dee7f3144df62d7fd5aa7i0" },
    { id: "e285ac04a6f7e4cf7a3c9b6159e84a8f9a10ce6788735fc1a298037b6df9a8c5i0" },
    { id: "0d5699ebdaadcabb19166741a6e340ca1560570ee130b5e515d1d8ba845c8747i0" },
    { id: "bc31ea4a536985607e7627404ba6c4cfdfd1fe4af2012cfe459bfe8d7aa64f29i0" },
    { id: "fa1b8adb350578cc15d995720069e35fee14eb69e7f087c67df4404164c944a1i0" },
    { id: "35ca04dcab4c3fd4e408b54289ff9ab661409b1cb34c2dad38bce6a1d041f8d7i0" },
    { id: "941ec6547466e2caad5e0995084b206d8093e7cdf6847e83ea7e97853c8abee7i0" },
    { id: "e8cbc25276d320dab84cb6d3f437f15877a92b3f71b63db48d7ef99d5bb47d51i0" },
    { id: "8281a2a51eb87a85c4409525b040b11823a9f2389e6f35d236965fbe952d1201i0" },
    { id: "c586feb3f8c8d135fee13fc29a2a97c868a42e5140d75e774d274704fab740ddi0" },
    { id: "324ea8d00c4dfd2df11174383f413123cebc0ef796907e1988f8ed19350350c3i0" },
    { id: "f6d1e33eb6b50fb57cabf089f74eb15f4048c51e5175dcbab9fd8fcdf3527b61i0" },
    { id: "1950a6a6524782e75831984e944e1e7b1396a791c632f6838c6b5b81e5d4b4bci0" },
    { id: "03720ffbfd686e0dcb5c5265efcf82bf2ac8d60888765f112df82664f5f3522ei0" },
    { id: "d13fb7b5268ab4443332b1fb5f8d828559494c7cbbb1873d6b35978d2cc4d8a9i0" },
    { id: "fe8133d6fc96dd9389ad465d85a934917c5b8ad20fecd0ee6d71ad529a1e50cci0" },
    { id: "7b7b54ee9178c7232e5cc3c0a57a2c9dd8728f340e40e9780dddf1f2ccc9320fi0" },
    { id: "7009da021c017d5b134c36eb14001fb0eee23fc15e504be3c77f4b0753d48c66i0" },
    { id: "67cd560261087f6b8a4b36d5fa8c28e8fcf3bfe564e7ce24bb6655665a6fef55i0" },
    { id: "b831d95f9c096da754caa724e87497659701538448cd472d4faddb42acb837cdi0" },
    { id: "e85562c7fd160357168f27a44e2dbc0fb4709a1e3ed3724254c7dcc9ddbd18bdi0" },
    { id: "0966193602ab0cb03b4d8acd2a9d60bd4cda6a82d4de5307cc44bc100cae589fi0" },
    { id: "eb4bee1382510791210148c36d96c87dc4948c195b3101dcd1a3dd0c4f38b9fci0" },
    { id: "0bf9d420c38a3909bf947534201d4cc467dd63145a2cb67b51b1b4877f45a71ci0" },
    { id: "ff33e27bc007dc991bb2c0e47d2a8e3bc5af45fa4a5a60c0f56be3e41640dc4fi0" },
    { id: "6b6c0269fd1c2c8780cb4c334c91738fe7732eaa31a712b1f7b48629eb3413dai0" },
    { id: "c8fdcda63fe94b1b501311b7f1f5a996c052f5d60e3f9d5e0330bf0c1c7ac710i0" },
    { id: "55b5423637f48e13196c71b8f3ffd361dfe429b780555257cd1af8e7c1af2f91i0" },
    { id: "81e8b16a845452a2b62d0f0a081ce737f99cd02330c70dfbb3414d00bc2d737ai0" },
    { id: "4a5b3308d8c94c344b064ee4df7e282db0116add1da6380ee54f0ab4338d87a0i0" },
    { id: "83772dcf53d165560dc9ee1f50171cdfbca5ee446e20e8a96a44432a6bae8533i0" },
    { id: "52482fc8fb23d37055cbb37e4aa4dfd287e0fe362e17f9b5522956dcf59c3925i0" },
    { id: "ce234c9cf1d5a39d6c4e42b8007fff610a12620765953d2bf3ac3d483cb450dei0" },
    { id: "ac66704227096a7139afd88ed74d53af422f88f3c00490609ac33a453241964ci0" },
    { id: "3c52b1becc36bfb10d11ea378300e69a410098567f915ae110061487eff3b88ei0" },
    { id: "bcbd836887aed3d0033bc43152fc1eda2e9f098560c319881301c2be76669c11i0" },
    { id: "766471e385978632680ae529c0df73d0e428f41e0efbc56da5f91b51936c6d20i0" },
    { id: "5655ff2399d143367d016bd88caae58f1e79d7f2350f4065297a7908a35b4eb9i0" },
    { id: "19e3f1e1e65160cf6b9999d74a81a5583e639bedc3fbd62ee4404d24bd1b094ei0" },
    { id: "7c1c5a19b7f2c08ac2cc2f2fe1e5f754ba7701a952a99af211e3cb584c374111i0" },
    { id: "7985e53d4a0931f9c1e37bcc81a6a1621e888a1b61e66a0f6f6cc221428e4852i0" },
    { id: "9588675f12ea1cf4bf756111ab657781399ed4d156ec7e1cd558325adc2d7f1ai0" },
    { id: "905bedc084956086e0689a367f67ffccb9cf756cbb2a3bc9d08a30c205ffe8adi0" },
    { id: "190719e8b32017353993432494dde6e503cbd27d6ce0e2729490eec5e2b6ddbdi0" },
  ];
  

  let array = [];
  test.map((a, index) =>
  array.push(a.id) 
  );

  console.log(array);

  const exportToJsonFile = () => {
    const data = JSON.stringify(array, null, 2);
    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "myArray.json";
    link.click();
  };

  return (
    <div>
      <button onClick={exportToJsonFile}>Export to JSON</button>
    </div>
  );
};

export default Snapshot;
